<template>
  <div class="flex flex-col">
    <div class="my-3">
      <MenuTab/>
    </div>
<!--    <div class="flex justify-center filters">-->
<!--      <Filters />-->
<!--    </div>-->
    <div class="w-full datatable">
      <Table :rows="_useHistorial._rows"/>
    </div>
    <div class="paginator">
      <Paginator/>
    </div>
  </div>
</template>
<script setup>
import { defineAsyncComponent } from 'vue'
import { useHistorialStore } from '../../../store/historial.store'
const _useHistorial = useHistorialStore()
const MenuTab = defineAsyncComponent(() => import('../../components/global/tabMenu.vue'))
const Paginator = defineAsyncComponent(() => import('../../components/historial/paginate.vue'))
const Table = defineAsyncComponent(() => import('../../components/historial/table.vue'))
// const Filters = defineAsyncComponent(() => import('../../components/historial/filters.vue'))
</script>
<style scoped>

</style>
